import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import * as routerSelectors from 'app/selectors/router';
import { push } from 'connected-react-router/immutable';
import { capitalize } from 'lodash';
import Header from 'app/components/customer/Header';
import FailureState from 'app/components/customer/dashboard/FailureState';
import * as Routes from 'app/constants/Routes';
import { guestRegistrationTermsSubmitted, NOOP } from 'app/actions/customer';
import { AvailableProducts, MultiMonthPlanMapping, UserSelectedSubProductMapping } from 'app/constants/Products';
import { hotjarIdentity, segmentAlias } from 'app/utils';
import Step2 from './Step2';
import NameStep from '../Parts/Step3';
import AccountRegistrationStep from '../Parts/Step4';
import StepMultimonthPlanSelection from './StepMultimonthPlanSelection';
import { KingV2SubProducts } from 'app/constants/Products';
import '../css/Registration.scss';

const mapStateToProps = (state) => {
  const feedbackParam = routerSelectors.selectRouterQuery(state).get('registration_feedback');
  const testMode = routerSelectors.selectRouterQuery(state).get('test_mode');
  const subProduct = (() => {
    const sub_product = routerSelectors.selectRouterQuery(state).get('sub_product');

    return Object.keys(UserSelectedSubProductMapping).find((key) =>
      UserSelectedSubProductMapping[key].includes(sub_product),
    );
  })();
  const currentPlan = (() => {
    const plan = routerSelectors.selectRouterQuery(state).get('plan');
    const capitalizedPlan = capitalize(plan);

    const planKey = Object.keys(MultiMonthPlanMapping).find((key) => MultiMonthPlanMapping[key] === capitalizedPlan);

    return planKey ? parseInt(planKey, 10) : null;
  })();
  const cpn = routerSelectors.selectRouterQuery(state).get('cpn');

  return {
    feedbackParam,
    initialValues: {
      settings: {
        product: AvailableProducts.King,
        test_mode: testMode,
        sub_product: subProduct,
        multimonth_plan: currentPlan,
        cpn: cpn,
      },
      step0: {
        state: '',
        pc_opt_in: false,
        mso_opt_in: false,
        over_18: false,
      },
      step1: {
        first_name: '',
        last_name: '',
      },
      step2: {
        email: window.emailFromTypeform,
        password: '',
        password_confirmation: '',
      },
    },
  };
};

const KingRegistration = ({ feedbackParam, initialValues, dispatch }) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [allValues, setAllValues] = useState(initialValues);
  const [currentStep, setCurrentStep] = useState(0);
  const [emailExist, setEmailExist] = useState(false);
  const stepValue = allValues[`step${currentStep}`];
  const [planSelected, setPlanSelected] = useState(false);

  const onSubmitNextStep = useCallback(
    (values) => {
      setAllValues((allValues) => ({
        ...allValues,
        [`step${currentStep}`]: values,
      }));
      setCurrentStep(currentStep + 1);
    },
    [currentStep],
  );

  const handleChangeProduct = (params: any) => {
    setAllValues((allValues) => ({
      ...allValues,
      ['settings']: {
        ...allValues.settings,
        multimonth_plan: params.multimonth_plan,
        sub_product: KingV2SubProducts.Enclo,
      },
    }));
    setPlanSelected(true);
  };

  const initialValuesMultimonthPlan = {
    multimonth_plan: allValues.settings?.multimonth_plan || 12,
    selected_king_v2_product: KingV2SubProducts.Enclo,
  };

  const onSubmitSendData = useCallback(
    async (values, form) => {
      const email = values.email.trim();
      const action = {
        params: {
          ...Object.values(allValues as Record<string, Record<string, any>>).reduce(
            (reduced, val) => ({ ...reduced, ...val }),
            {},
          ),
          ...values,
          email,
          href: window.location.href,
          referrer: window.referrer,
        },
        context: {
          onSuccessActionCreator: ({ json: { registration_feedback, user_id } }) => {
            if (registration_feedback === 'need_sign_in') {
              form.setFieldValue('password', '');
              form.setFieldTouched('password', false);
              form.setFieldValue('password_confirmation', '');
              form.setFieldTouched('password_confirmation', false);
              setEmailExist(true);
            } else {
              if (user_id) {
                hotjarIdentity(user_id);
                segmentAlias(user_id);
              }

              if (registration_feedback) {
                return push(`${Routes.Registration}?registration_feedback=${registration_feedback}`);
              }

              window.location.href = Routes.ContinueOnboarding;
            }

            return { type: NOOP };
          },
          onFailureActionCreator: ({ json: { errors } }) => {
            form.setErrors(errors);

            return { type: NOOP };
          },
        },
      };

      Cookies.set('email', email);
      await dispatch(guestRegistrationTermsSubmitted(action));
    },
    [dispatch, allValues],
  );

  const isValidState = window.kingV2AvailableStates.includes(allValues.step0?.state);
  const isOralTrt = [
    KingV2SubProducts.OralTrt,
    KingV2SubProducts.EncloTrt,
    KingV2SubProducts.Topical,
    KingV2SubProducts.EncloTopical,
    KingV2SubProducts.Injectable,
    KingV2SubProducts.InjectableHCG,
    KingV2SubProducts.EncloInjectable,
  ].includes(allValues.settings?.sub_product);
  const showPlanSelection = !planSelected && isOralTrt && !isValidState;

  const content = useMemo(
    () => (
      <>
        {currentStep === 0 && <Step2 initialValues={stepValue} onSubmit={onSubmitNextStep} />}
        {currentStep === 1 && showPlanSelection && (
          <StepMultimonthPlanSelection onSubmit={handleChangeProduct} initialValues={initialValuesMultimonthPlan} />
        )}
        {currentStep === 1 && !showPlanSelection && <NameStep initialValues={stepValue} onSubmit={onSubmitNextStep} />}
        {currentStep === 2 && (
          <AccountRegistrationStep initialValues={stepValue} emailExist={emailExist} onSubmit={onSubmitSendData} />
        )}
      </>
    ),
    [currentStep, emailExist, stepValue, allValues.step0, onSubmitSendData, onSubmitNextStep],
  );

  if (feedbackParam) {
    return (
      <div className="grid-container">
        <Header style="black" />
        <main>
          <FailureState state={feedbackParam} />
        </main>
      </div>
    );
  }

  return <div className="centered">{content}</div>;
};

export default connect(mapStateToProps)(KingRegistration);
