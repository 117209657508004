import { WarriorSubProducts } from 'app/constants/Products';

export const WarriorSubProductsConfig = {
  [WarriorSubProducts.OralDutasteride]: {
    recommended_for:
      'The most powerful daily pill inhibits two enzymes that create DHT (the male hormone associated with hair loss).',
    active_ingredients: [
      {
        name: 'Dutasteride 0.5mg',
        description: 'Dutasteride is a powerful 5-alpha reductase inhibitor that when taken orally is very effective.',
      },
    ],
  },
  [WarriorSubProducts.OralFinasteride]: {
    recommended_for:
      'A powerful daily pill inhibits one enzyme that creates DHT (the male hormone associated with hair loss). Recommended for customers who prefer Finasteride instead of Dutasteride.',
    active_ingredients: [
      {
        name: 'Finasteride 1mg',
        description: 'Lorem ipusum somsghi orem ipusum somsghi orem ipusum somsghi orem ipusum somsghi',
      },
    ],
  },
  [WarriorSubProducts.OralMinoxidil]: {
    recommended_for:
      'An easy daily pill that promotes hair regrowth without impacting hormones and may be more effective than topical in some individuals.',
    active_ingredients: [
      {
        name: 'Minoxidil 2.5mg',
        description:
          'Minoxidil works as a vasodilator, which means it widens blood vessels, improving blood flow to hair follicles. This enhanced blood flow brings more nutrients and oxygen to the follicles, extending the anagen (growth) phase of the hair cycle and stimulating dormant follicles to produce new hair, resulting in thicker and denser hair.\n Minoxidil is a potent compound that is available in both oral and topical forms. Both forms are effective for increasing terminal hair density and total hair density, but oral minoxidil’s benefits come with easier compliance. Topical minoxidil will localize the hair growth benefits whereas oral minoxidil can lead to systemic effects.',
      },
    ],
  },
  [WarriorSubProducts.TopicalMinoxidil]: {
    recommended_for:
      'Our proven topical treatment delivers targeted hair regrowth without impacting hormones and minimizes systemic effects.',
    active_ingredients: [
      {
        name: 'Minoxidil 5%',
        description:
          'Minoxidil works as a vasodilator, which means it widens blood vessels, improving blood flow to hair follicles. This enhanced blood flow brings more nutrients and oxygen to the follicles, extending the anagen (growth) phase of the hair cycle and stimulating dormant follicles to produce new hair, resulting in thicker and denser hair.',
      },
      {
        name: 'Tretinoin 0.1%',
        description:
          'A derivative of vitamin A, accelerates cell turnover and promotes the shedding of old skin cells. This process helps to clear clogged hair follicles and enhances the absorption of other topical treatments like minoxidil. By keeping follicles clear and promoting new cell growth, Tretinoin can stimulate hair regrowth.',
      },
      {
        name: 'Fexofenadine 0.1%',
        description:
          'An antihistamine that reduces inflammation and allergic responses in the scalp. While it does not directly stimulate hair growth, it helps to create a healthier scalp environment by reducing irritation and inflammation, which can support overall hair health.',
      },
      {
        name: 'Melatonin 0.0033%',
        description:
          'Commonly known for regulating sleep, also has powerful antioxidant properties. It protects hair follicles from oxidative stress (damage caused by free radicals) and helps to extend the anagen phase of the hair cycle. This dual action promotes healthier and sustained hair growth.',
      },
      {
        name: 'Caffeine 0.2%',
        description:
          'Acts as a stimulant not only for your mind but also for your hair follicles. It blocks the effects of DHT, the hormone that shrinks hair follicles. Additionally, caffeine increases cellular energy levels, promoting hair growth and prolonging the anagen phase. It is often used in topical applications to directly stimulate hair follicles.',
      },
    ],
  },
  [WarriorSubProducts.TopicalDutasteride]: {
    recommended_for:
      'A perfect topical treatment for reducing hair loss and promoting regrowth. Targeted formula without Minoxidil for customers who may have experienced irritation or side effects in the past.',
    active_ingredients: [
      {
        name: 'Dutasteride 0.1%',
        description:
          'A powerful 5-alpha reductase inhibitor. Topical forms are effective but avoid potential side effects, such as an impact on libido, erectile dysfunction, or ejaculation dysfunction.',
      },
      {
        name: 'Spironolactone 1%',
        description:
          'Acts locally on the scalp to block androgen receptors. Androgens can bind to hair follicles and cause them to shrink. By blocking these hormones at the follicle level, spironolactone prevents follicular damage and supports hair regrowth without affecting hormone levels throughout the body.',
      },
      {
        name: 'Tretinoin 0.1%',
        description:
          'A derivative of vitamin A, accelerates cell turnover and promotes the shedding of old skin cells. This process helps to clear clogged hair follicles and enhances the absorption of other topical treatments like minoxidil. By keeping follicles clear and promoting new cell growth, Tretinoin can stimulate hair regrowth.',
      },
      {
        name: 'Fexofenadine 0.1%',
        description:
          'An antihistamine that reduces inflammation and allergic responses in the scalp. While it does not directly stimulate hair growth, it helps to create a healthier scalp environment by reducing irritation and inflammation, which can support overall hair health.',
      },
      {
        name: 'Melatonin 0.0033%',
        description:
          'Commonly known for regulating sleep, also has powerful antioxidant properties. It protects hair follicles from oxidative stress (damage caused by free radicals) and helps to extend the anagen phase of the hair cycle. This dual action promotes healthier and sustained hair growth.',
      },
      {
        name: 'Caffeine 0.2%',
        description:
          'Acts as a stimulant not only for your mind but also for your hair follicles. It blocks the effects of DHT, the hormone that shrinks hair follicles. Additionally, caffeine increases cellular energy levels, promoting hair growth and prolonging the anagen phase. It is often used in topical applications to directly stimulate hair follicles.',
      },
    ],
  },
  [WarriorSubProducts.OralDutasterideOralMinoxidil]: {
    recommended_for:
      'Our most effective oral treatment, designed to reduce hair loss and promote hair regrowth. Easy once-a-day pills.',
    active_ingredients: [
      {
        name: 'Dutasteride 0.5mg',
        description: 'Dutasteride is a powerful 5-alpha reductase inhibitor that when taken orally is very effective.',
      },
      {
        name: 'Minoxidil 2.5mg',
        description:
          'Minoxidil works as a vasodilator, which means it widens blood vessels, improving blood flow to hair follicles. This enhanced blood flow brings more nutrients and oxygen to the follicles, extending the anagen (growth) phase of the hair cycle and stimulating dormant follicles to produce new hair, resulting in thicker and denser hair.\n' +
          'Minoxidil is a potent compound that is available in both oral and topical forms. Both forms are effective for increasing terminal hair density and total hair density, but oral minoxidil’s benefits come with easier compliance. Topical minoxidil will localize the hair growth benefits whereas oral minoxidil can lead to systemic effects.',
      },
    ],
  },
  [WarriorSubProducts.OralMinoxidilTopicalDutasteride]: {
    recommended_for:
      'A powerful combination protocol for reducing hair loss and promoting regrowth. Includes oral Minoxidil for customers who may have experienced irritation or side effects from topical in the past.',
    active_ingredients: [
      {
        name: 'Minoxidil 2.5mg',
        description:
          'Minoxidil works as a vasodilator, which means it widens blood vessels, improving blood flow to hair follicles. This enhanced blood flow brings more nutrients and oxygen to the follicles, extending the anagen (growth) phase of the hair cycle and stimulating dormant follicles to produce new hair, resulting in thicker and denser hair.\n' +
          'Minoxidil is a potent compound that is available in both oral and topical forms. Both forms are effective for increasing terminal hair density and total hair density, but oral minoxidil’s benefits come with easier compliance. Topical minoxidil will localize the hair growth benefits whereas oral minoxidil can lead to systemic effects.',
      },
      {
        name: 'Dutasteride 0.1%',
        description:
          'A powerful 5-alpha reductase inhibitor. Topical forms are effective but avoid potential side effects, such as an impact on libido, erectile dysfunction, or ejaculation dysfunction.',
      },
      {
        name: 'Spironolactone 1%',
        description:
          'Acts locally on the scalp to block androgen receptors. Androgens can bind to hair follicles and cause them to shrink. By blocking these hormones at the follicle level, spironolactone prevents follicular damage and supports hair regrowth without affecting hormone levels throughout the body.',
      },
      {
        name: 'Tretinoin 0.1%',
        description:
          'A derivative of vitamin A, accelerates cell turnover and promotes the shedding of old skin cells. This process helps to clear clogged hair follicles and enhances the absorption of other topical treatments like minoxidil. By keeping follicles clear and promoting new cell growth, Tretinoin can stimulate hair regrowth.',
      },
      {
        name: 'Fexofenadine 0.1%',
        description:
          'An antihistamine that reduces inflammation and allergic responses in the scalp. While it does not directly stimulate hair growth, it helps to create a healthier scalp environment by reducing irritation and inflammation, which can support overall hair health.',
      },
      {
        name: 'Melatonin 0.0033%',
        description:
          'Commonly known for regulating sleep, also has powerful antioxidant properties. It protects hair follicles from oxidative stress (damage caused by free radicals) and helps to extend the anagen phase of the hair cycle. This dual action promotes healthier and sustained hair growth.',
      },
      {
        name: 'Caffeine 0.2%',
        description:
          'Acts as a stimulant not only for your mind but also for your hair follicles. It blocks the effects of DHT, the hormone that shrinks hair follicles. Additionally, caffeine increases cellular energy levels, promoting hair growth and prolonging the anagen phase. It is often used in topical applications to directly stimulate hair follicles.',
      },
    ],
  },
  [WarriorSubProducts.OralDutasterideTopicalMinoxidil]: {
    recommended_for:
      'Our strongest combination protocol that combines the effectiveness of dual DHT blockers as well as trusted targeted topical regrowth.',
    active_ingredients: [
      {
        name: 'Dutasteride 0.5mg',
        description: 'Dutasteride is a powerful 5-alpha reductase inhibitor that when taken orally is very effective.',
      },
      {
        name: 'Minoxidil 5%',
        description:
          'Minoxidil works as a vasodilator, which means it widens blood vessels, improving blood flow to hair follicles. This enhanced blood flow brings more nutrients and oxygen to the follicles, extending the anagen (growth) phase of the hair cycle and stimulating dormant follicles to produce new hair, resulting in thicker and denser hair.',
      },
      {
        name: 'Tretinoin 0.1%',
        description:
          'A derivative of vitamin A, accelerates cell turnover and promotes the shedding of old skin cells. This process helps to clear clogged hair follicles and enhances the absorption of other topical treatments like minoxidil. By keeping follicles clear and promoting new cell growth, Tretinoin can stimulate hair regrowth.',
      },
      {
        name: 'Fexofenadine 0.1%',
        description:
          'An antihistamine that reduces inflammation and allergic responses in the scalp. While it does not directly stimulate hair growth, it helps to create a healthier scalp environment by reducing irritation and inflammation, which can support overall hair health.',
      },
      {
        name: 'Melatonin 0.0033%',
        description:
          'Commonly known for regulating sleep, also has powerful antioxidant properties. It protects hair follicles from oxidative stress (damage caused by free radicals) and helps to extend the anagen phase of the hair cycle. This dual action promotes healthier and sustained hair growth.',
      },
      {
        name: 'Caffeine 0.2%',
        description:
          'Acts as a stimulant not only for your mind but also for your hair follicles. It blocks the effects of DHT, the hormone that shrinks hair follicles. Additionally, caffeine increases cellular energy levels, promoting hair growth and prolonging the anagen phase. It is often used in topical applications to directly stimulate hair follicles.',
      },
    ],
  },
  [WarriorSubProducts.TopicalFiveInOne]: {
    recommended_for:
      'Our most effective topical treatment, designed to reduce hair loss and promote hair regrowth. Targeted gel that minimizes systemic effects.',
    active_ingredients: [
      {
        name: 'Dutasteride 0.1%',
        description:
          'A powerful 5-alpha reductase inhibitor. Topical forms are effective but avoid potential side effects, such as an impact on libido, erectile dysfunction, or ejaculation dysfunction.',
      },
      {
        name: 'Spironolactone 1%',
        description:
          'Acts locally on the scalp to block androgen receptors. Androgens can bind to hair follicles and cause them to shrink. By blocking these hormones at the follicle level, spironolactone prevents follicular damage and supports hair regrowth without affecting hormone levels throughout the body.',
      },
      {
        name: 'Minoxidil 5%',
        description:
          'Minoxidil works as a vasodilator, which means it widens blood vessels, improving blood flow to hair follicles. This enhanced blood flow brings more nutrients and oxygen to the follicles, extending the anagen (growth) phase of the hair cycle and stimulating dormant follicles to produce new hair, resulting in thicker and denser hair.',
      },
      {
        name: 'Tretinoin 0.1%',
        description:
          'A derivative of vitamin A, accelerates cell turnover and promotes the shedding of old skin cells. This process helps to clear clogged hair follicles and enhances the absorption of other topical treatments like minoxidil. By keeping follicles clear and promoting new cell growth, Tretinoin can stimulate hair regrowth.',
      },
      {
        name: 'Fexofenadine 0.1%',
        description:
          'An antihistamine that reduces inflammation and allergic responses in the scalp. While it does not directly stimulate hair growth, it helps to create a healthier scalp environment by reducing irritation and inflammation, which can support overall hair health.',
      },
      {
        name: 'Melatonin 0.0033%',
        description:
          'Commonly known for regulating sleep, also has powerful antioxidant properties. It protects hair follicles from oxidative stress (damage caused by free radicals) and helps to extend the anagen phase of the hair cycle. This dual action promotes healthier and sustained hair growth.',
      },
      {
        name: 'Caffeine 0.2%',
        description:
          'Acts as a stimulant not only for your mind but also for your hair follicles. It blocks the effects of DHT, the hormone that shrinks hair follicles. Additionally, caffeine increases cellular energy levels, promoting hair growth and prolonging the anagen phase. It is often used in topical applications to directly stimulate hair follicles.',
      },
    ],
  },
};
