import PageHeader from 'app/components/common/PageHeader';
import React, { useRef, useState } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import {
  selectCurrentIntake,
  selectCurrentIntakeProduct,
  selectCustomer,
  selectCustomerShippingAddress,
  selectLatestPaymentMethodErrorMessage,
} from 'app/selectors/customer';
import { useFormikContext, FormikProps } from 'formik';
import 'app/components/customer/steps/Payment/css/Payment.scss';
import 'app/components/customer/steps/Payment/css/GenericPaymentPage.scss';
import GenericShippingCard from 'app/components/customer/steps/Payment/Generic/GenericShippingCard';
import GenericPaymentMethod from 'app/components/customer/steps/Payment/Generic/GenericPaymentMethod';
import * as selectors from 'app/selectors/customer';
import GenericPaymentHeader from 'app/components/customer/steps/Payment/Generic/GenericPaymentHeader';
import { push } from 'connected-react-router/immutable';
import GenericDueToday from 'app/components/customer/steps/Payment/Generic/GenericDueToday';
import GenericSelfPay from 'app/components/customer/steps/Payment/Generic/GenericSelfPay';
import SubscriptionWidget from 'app/components/customer/steps/Payment/Generic/SubscriptionWidget';
import { MultiMonthPlanMapping, KingV2SubProducts, LabKitTypes } from 'app/constants/Products';
import { apiRequestUserCommand, NOOP } from 'app/actions/customer';
import GenericShippingForm from './GenericShippingForm';
import { useDispatch } from 'react-redux';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import OwnLabFileUploadedSection from 'app/components/customer/steps/Payment/Generic/OwnLabFileUploadedSection';
import axios from 'axios';
import { getCSRF } from 'app/api';
import * as actions from 'app/actions/customer';
import UnavailableProductModal from 'app/components/customer/steps/Payment/Generic/UnavailableProductModal';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import DiscountBanner from '../DiscountBanner';
import { useExperiment } from 'app/utils/useExperiment';

type Props = {
  isOnboarding: boolean;
  errorMessage?: string;
  setOptInParams?: (e: any) => void;
};

const GenericPaymentForm = ({ errorMessage, setOptInParams, isOnboarding }: Props) => {
  const dispatch = useDispatch();
  const { values, handleSubmit, isSubmitting, isValid, setFieldValue } = useFormikContext<{
    include_supplement: boolean;
    use_own_lab: boolean;
    lab_kit_type: LabKitTypes;
    own_lab_file_uploaded: boolean;
    selfPay: boolean;
    daily_strength: string;
    drug_variant: string;
    number_of_doses: 4 | 12 | 8;
    multimonth_plan: 1 | 3 | 6 | 12;
    selected_king_v2_product: KingV2SubProducts;
    include_dermastamp: boolean;
  }>();
  const { selfPay, multimonth_plan } = values;
  const customer = useAppSelector(selectCustomer);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, currentProductName));
  const intake = useAppSelector(selectCurrentIntake);

  const subscriptionPaidSuccessfuly = intake.get('customer_subscription_successful');
  const labPaidSuccessfuly = intake.get('customer_lab_payment_successful');

  const labsFreeExperiment =
    useExperiment('testosterone_discount_labs_free', 'testosterone_discount_labs_free_variation_0') ===
    'testosterone_discount_labs_free_variation_1';

  const isLabsFree = isOnboarding && currentProductName === 'king' && labsFreeExperiment;

  const checkoutHelper = createCheckoutHelper(intake, customer, product, true);
  const priceCalculator = checkoutHelper.priceCalculator(
    values,
    subscriptionPaidSuccessfuly,
    isLabsFree || labPaidSuccessfuly,
  );

  const latestPaymentMethodErrorMessage = useAppSelector(selectLatestPaymentMethodErrorMessage);

  const subProductName = checkoutHelper.subProductNameForIntakeCheckout(product);
  const multimonthPeriod = MultiMonthPlanMapping[multimonth_plan];

  const paymentErrorMessage = errorMessage || customer.get('payment_error_message') || latestPaymentMethodErrorMessage;
  const currentPaymentMethod = customer.get('payment_method');

  const showLabKit = checkoutHelper.showLabKitOnboarding();
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);
  const ownLabActions = checkoutHelper.showOwnLabSection();
  const isKingV2OralTrt = checkoutHelper.isKingV2OralTrt(subProductName);
  const separateLabStepPresented = intake.get('steps').includes('lab_selection');

  const [shippingFormValid, setShippingFormValid] = useState(false);
  const [oralTrtAvailable, setOralTrtAvailable] = useState(true);
  const [unavailableProductDialog, setUnavailableProductDialog] = useState(false);

  const onMultimonthPlanChange = (value) => {
    const action = apiRequestUserCommand({
      cmdType: 'select_multimonth_plan',
      params: { multimonth_plan: value, intake: intake.get('name') },
      context: {},
    });
    dispatch(action);
  };

  const shippingFormRef = useRef<FormikProps<any>>(null);
  const onSubmit = async (applePayPaymentMethod: any = null) => {
    if (!shippingAddress && !shippingFormValid) return;

    if (isKingV2OralTrt) {
      const customerState = shippingFormRef.current?.values?.state || shippingAddress.get('state');
      const kingV2AvailableStates = intake.get('king_v2_available_states');
      const validOralTrtState = kingV2AvailableStates.includes(customerState);
      setUnavailableProductDialog(!validOralTrtState);
      setOralTrtAvailable(validOralTrtState);
      if (!validOralTrtState) return;
    }

    if (shippingFormRef.current?.dirty) {
      const response = (await shippingFormRef.current.submitForm()) as { type: string } | undefined;
      if (response?.type === NOOP) return;
    }

    if (applePayPaymentMethod?.paymentMethodId) {
      setFieldValue('applePayPaymentMethod', applePayPaymentMethod);
    }

    handleSubmit();
  };

  const renderDueToday = () => (
    <GenericDueToday
      multimonthPeriod={multimonthPeriod}
      isOnboarding={isOnboarding}
      priceCalculator={priceCalculator}
      showLabKit={showLabKit}
      intake={intake}
    />
  );

  const [productPharmacyValid, setProductPharmacyValid] = useState(true);
  const lab_orders = customer?.get('lab_orders')?.toArray();
  const intakeName = intake.get('name');
  const lab_order = lab_orders?.find((order) => order.get('intake_name') === intakeName);

  const removeLabAction = actions.apiRequestUserCommand({
    cmdType: 'remove_lab_order',
    params: { lab_order_id: lab_order?.get('order_id') },
    context: { onSuccessAction: push('lab_selection') },
  });

  const changeLabSelection = async () => {
    if (!checkoutHelper.useOwnLab()) {
      dispatch(push('lab_selection'));
      return;
    }

    const photoPurpose = `${intake.get('product_name')}_onboarding_lab`;
    await axios.post(
      '/api/commands',
      {
        user_id: customer.get('id'),
        intakeName,
        photo_purpose: photoPurpose,
        cmdType: 'delete_own_lab',
        type: 'delete_own_lab',
      },
      { headers: { 'X-CSRF-Token': getCSRF() } },
    );
    dispatch(removeLabAction);
  };

  const renderSubscriptionWidget = () => {
    return (
      <SubscriptionWidget
        currentProductName={currentProductName}
        product={product}
        priceCalculator={priceCalculator}
        goToSelectProductPage={() => dispatch(push(checkoutHelper.selectProductStepPath()))}
        goToSelectLabPage={separateLabStepPresented && changeLabSelection}
        multimonthPeriod={multimonthPeriod}
        multimonthPlan={multimonth_plan}
        isOnboarding={isOnboarding}
        supplementsOnAnotherIntake={checkoutHelper.supplementsOnAnotherIntake()}
        multimonthEnabled={checkoutHelper.multimonthEnabled()}
        checkoutHelper={checkoutHelper}
        onMultimonthPlanChange={onMultimonthPlanChange}
        productChanged={false}
        subProductName={subProductName}
        activeProductWithSupplementName={checkoutHelper.activeProductWithSupplementName()}
      />
    );
  };

  const renderShippingAddressWidget = () => {
    return (
      !shippingAddress && (
        <GenericShippingForm
          shippingFormRef={shippingFormRef}
          productPharmacyValid={productPharmacyValid}
          setProductPharmacyValid={setProductPharmacyValid}
          setShippingFormValid={setShippingFormValid}
        />
      )
    );
  };

  const renderGenericCheckoutSection = () => {
    return (
      <>
        {renderDueToday()}
        {!!shippingAddress && <GenericShippingCard />}
        <GenericSelfPay />
        <GenericPaymentMethod
          paymentErrorMessage={paymentErrorMessage}
          currentPaymentMethod={currentPaymentMethod}
          setOptInParams={setOptInParams}
          onCheckout={onSubmit}
          priceCalculator={priceCalculator}
          shippingFormValid={shippingFormValid}
          shippingFormRef={shippingFormRef}
          setUnavailableProductDialog={setUnavailableProductDialog}
          productPharmacyValid={productPharmacyValid}
          disabled={
            isSubmitting ||
            !isValid ||
            !selfPay ||
            !productPharmacyValid ||
            (!shippingAddress && !shippingFormValid) ||
            (isKingV2OralTrt && !oralTrtAvailable)
          }
        />
      </>
    );
  };

  const renderOralTrtUnavailableModal = () => {
    return (
      <SetProductDialog
        isOpen={unavailableProductDialog}
        title="Oral TRT is Not Currently Available in Your State"
        text={
          <UnavailableProductModal
            setUnavailableProductDialog={setUnavailableProductDialog}
            setOralTrtAvailable={setOralTrtAvailable}
          />
        }
        className="manage_subscription__cancel_modal align-left"
        showCloseModalIcon={false}
      />
    );
  };

  return (
    <div className="generic_payment_page centered updated-design payments">
      <DiscountBanner />
      <PageHeader title="Checkout" className="treatment_plan__header mb24 -edged" />
      <div className="content">
        <GenericPaymentHeader
          currentProductName={currentProductName}
          multimonthEnabled={checkoutHelper.multimonthEnabled()}
        />
        {renderSubscriptionWidget()}
        {ownLabActions && <OwnLabFileUploadedSection />}
        {renderShippingAddressWidget()}
        {renderGenericCheckoutSection()}
        {renderOralTrtUnavailableModal()}
      </div>
    </div>
  );
};

export default GenericPaymentForm;
