import React from 'react';

import { useFormikContext } from 'formik';
import { AvailableProducts, WarriorSubProducts } from 'app/constants/Products';
import { ImmutableList } from 'app/types/admin';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import { SecondaryButton } from 'app/components/common/Button';
import { P } from 'app/components/common/Typography';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import subProductNames from 'app/utils/subProductNames';
import { WARRIOR_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';
import { WarriorSubProductsConfig } from 'app/components/customer/steps/Payment/warrior/WarriorSubProductConfig';

type Props = {
  availableSubProducts: ImmutableList<WarriorSubProducts> | null;
};

const WarriorSubProductsList = ({ availableSubProducts }: Props) => {
  const { setFieldValue } = useFormikContext();

  const renderProductCard = (subProductName: any) => {
    const subProduct = WarriorSubProductsConfig[subProductName];

    return (
      <PaymentCard className="product-selection mb32">
        <ProductHeader
          productName={AvailableProducts.Warrior}
          subProductName={subProductName}
          cardStyle={false}
          smallImage={true}
        />
        <div className="price_row mb16">
          <h4 className="subtitle mt8">{subProductNames[AvailableProducts.Warrior][subProductName]}</h4>
          <PriceFormatter price={WARRIOR_MONTHLY_PRICE[subProductName][3]} from={true} />
        </div>
        <P>
          <b className="bold">Recommended for: </b>
          {subProduct.recommended_for}
        </P>
        <SecondaryButton
          text="Continue"
          className="mt16"
          data-testid="own-lab-modal-close"
          onClick={() => setFieldValue('warrior_strength', subProductName)}
        />
      </PaymentCard>
    );
  };

  if (!availableSubProducts) return null;

  return (
    <div className="choose_product">
      {availableSubProducts.map((subProductName) => renderProductCard(subProductName))}
    </div>
  );
};

export default WarriorSubProductsList;
