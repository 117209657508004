import cx from 'classnames';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import React from 'react';
import Checkbox from 'app/components/common/Checkbox';
import { useFormikContext } from 'formik';
import PriceCalculator from 'app/helpers/priceCalculator';

type Props = {
  priceCalculator: PriceCalculator;
  isOnboarding: boolean;
};

const Dermastamp = ({ priceCalculator, isOnboarding }: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    include_dermastamp: boolean;
  }>();
  const { include_dermastamp } = values;

  if (values.include_dermastamp === null) {
    values.include_dermastamp = isOnboarding;
  }

  return (
    <>
      <div className={cx('price_row')}>
        <div>
          <Checkbox
            className="include-dermastamp-label"
            label={
              <div className={cx('price_row', { opacity: !include_dermastamp })}>
                <div>
                  <h4 className="generic_payment_page__bb_label">Add Dermastamp</h4>
                </div>
              </div>
            }
            testId="include-dermastamp"
            name="include_dermastamp"
            checked={values.include_dermastamp}
            onChange={(evt) => setFieldValue('include_dermastamp', evt.target.checked)}
          />
        </div>
        <PriceFormatter className={cx({ opacity: !include_dermastamp })} price={priceCalculator.dermastampPrice()} />
      </div>
    </>
  );
};

export default Dermastamp;
