import React from 'react';
import '../css/ProductImage.scss';
import BottleEncloPregnolone from 'images/sub-products/king/bottle_enclo_pregnolone.png';
import BottleTrt from 'images/sub-products/king/prod-testosterone-oral.png';
import BottleEncloPregnoloneTrt from 'images/sub-products/king/prod-testosterone-oral-enc.png';
import BottleTopical from 'images/sub-products/king/prod-testosterone-cream.png';
import BottleTopicalEnc from 'images/sub-products/king/prod-testosterone-gel-enc.png';
import BottleInjectable from 'images/sub-products/king/prod-testosterone-injectable.png';
import BottleInjectableEnc from 'images/sub-products/king/prod-testosterone-injectable-enc.png';
import BottleInjectableHCG from 'images/sub-products/king/prod-testosterone-injectable-hcg.png';
import BottleEnclo from 'images/sub-products/king/prod-testosterone-enc.png';
import BottleSemaglutide from 'images/sub-products/magician/bottle_semaglutide.png';
import BottleTirzepatide from 'images/sub-products/magician/bottle_tirzepatide.png';
import LoverDrugs from 'images/sub-products/lover/lover-drugs.png';
import BottleOralMinoxidil from 'images/sub-products/warrior/oral_minoxidil.png';
import BottleOralDutasteride from 'images/sub-products/warrior/oral_dutasteride.png';
import BottleOralFinasteride from 'images/sub-products/warrior/oral_finasteride.png';
import BottleTopicalMinoxidil from 'images/sub-products/warrior/topical_minoxidil.svg';
import BottleTopicalDutasteride from 'images/sub-products/warrior/topical_dutasteride.png';
import BottleTopicalFiveInOne from 'images/sub-products/warrior/topical_five_in_one.png';
import BottleOralDutasterideOralMinoxidil from 'images/sub-products/warrior/oral_dutasteride_oral_minoxidil.png';
import BottleOralDutasterideTopicalMinoxidil from 'images/sub-products/warrior/oral_dutasteride_topical_minoxidil.png';
import BottleOralMinoxidilTopicalDutasteride from 'images/sub-products/warrior/oral_minoxidil_topical_dutasteride.png';
import { AvailableProducts, KingV2SubProducts, MagicianSubProducts, WarriorSubProducts } from 'app/constants/Products';

const ProductImage = ({ productName, subProductName, className = '' }) => {
  const ImagesMapping = {
    [AvailableProducts.King]: {
      [KingV2SubProducts.Enclo]: BottleEnclo,
      [KingV2SubProducts.EncloPregnolone]: BottleEncloPregnolone,
      [KingV2SubProducts.OralTrt]: BottleTrt,
      [KingV2SubProducts.EncloPregnoloneTrt]: BottleEncloPregnoloneTrt,
      [KingV2SubProducts.EncloTrt]: BottleEncloPregnoloneTrt,
      [KingV2SubProducts.Topical]: BottleTopical,
      [KingV2SubProducts.EncloTopical]: BottleTopicalEnc,
      [KingV2SubProducts.Injectable]: BottleInjectable,
      [KingV2SubProducts.EncloInjectable]: BottleInjectableEnc,
      [KingV2SubProducts.InjectableHCG]: BottleInjectableHCG,
    },
    [AvailableProducts.Magician]: {
      [MagicianSubProducts.Semaglutide]: BottleSemaglutide,
      [MagicianSubProducts.Tirzepatide]: BottleTirzepatide,
    },
    [AvailableProducts.Lover]: LoverDrugs,
    [AvailableProducts.Warrior]: {
      [WarriorSubProducts.OralMinoxidil]: BottleOralMinoxidil,
      [WarriorSubProducts.OralDutasteride]: BottleOralDutasteride,
      [WarriorSubProducts.OralFinasteride]: BottleOralFinasteride,
      [WarriorSubProducts.TopicalMinoxidil]: BottleTopicalMinoxidil,
      [WarriorSubProducts.TopicalDutasteride]: BottleTopicalDutasteride,
      [WarriorSubProducts.TopicalFiveInOne]: BottleTopicalFiveInOne,
      [WarriorSubProducts.OralMinoxidilTopicalDutasteride]: BottleOralMinoxidilTopicalDutasteride,
      [WarriorSubProducts.OralDutasterideTopicalMinoxidil]: BottleOralDutasterideTopicalMinoxidil,
      [WarriorSubProducts.OralDutasterideOralMinoxidil]: BottleOralDutasterideOralMinoxidil,
    },
  };

  return (
    <div className={`product-image ${className}`}>
      <img
        src={
          productName == AvailableProducts.Lover
            ? ImagesMapping[productName]
            : ImagesMapping[productName][subProductName]
        }
      />
    </div>
  );
};

export default ProductImage;
