import { P } from 'app/components/common/Typography';
import React, { useState } from 'react';
import { PrimaryButton } from 'app/components/common/Button';
import Checkbox from 'app/components/common/Checkbox';
import './SafetyAcknowlegment.scss';

const WarriorSafetyAcknowledgment = ({ handleSubmit }) => {
  const [risksAcknwledged, setRisksAcknowledged] = useState(false);

  const handleClick = (event) => {
    setRisksAcknowledged(event.target.checked);
  };

  const renderFooter = () => (
    <div className="safety-modal-footer">
      <div className="flex-container">
        <Checkbox onChange={handleClick} checked={risksAcknwledged} testId="confirm-risk-acknowledgment-checkbox" />
        <P>
          By acknowledging this safety information, I confirm that I will promptly report any adverse effects, changes
          to my medical condition, or changes to medications to the clinical team to ensure my safety and the efficacy
          of my treatment.
        </P>
      </div>

      <PrimaryButton
        className="mt24"
        text="Confirm"
        type="button"
        onClick={handleSubmit}
        disabled={!risksAcknwledged}
        data-testid="confirm-risk-acknowledgment"
      />
    </div>
  );

  return (
    <div className="safety-modal">
      <div className="safety-modal-content">
        <div className="mb12">
          <P>
            Please take a moment to review this important safety information regarding dutasteride and finasteride. This
            does not replace discussions with your doctor or healthcare provider about your medical condition or
            treatment.
          </P>
        </div>

        <div className="mb12">
          <P className="bold">Psychological Side Effects</P>
          <P>
            While most people tolerate dutasteride well, a few individuals have reported mild mood changes, such as
            feelings of depression or anxiety. It has also been linked to an increase in suicidal thoughts/behaviors.
            These occurrences are uncommon. If you notice any significant changes in your mood, please consult your
            healthcare provider.
          </P>
        </div>

        <div className="mb12">
          <P className="bold">Fertility Considerations</P>
          <P>
            5-alpha reductase inhibitors may temporarily affect semen parameters, such as sperm count or motility, in
            some individuals. These changes are generally reversible upon discontinuation of the medication. If you are
            planning to conceive or have concerns about fertility, please discuss this with your healthcare provider to
            ensure the best approach for your needs.
          </P>
        </div>

        <div className="mb12">
          <P className="bold">Other Possible Side Effects</P>
          <P>
            Some individuals might experience minor scalp-related issues when using topical formulations, such as
            itching, dryness, or irritation. These symptoms are usually mild and often resolve over time. Rarely, some
            people may notice breast tenderness or enlargement.
          </P>
        </div>

        <div className="mb12">
          <P className="bold">Likelihood of Side Effects</P>
          <P>
            <a href="https://pubmed.ncbi.nlm.nih.gov/27672412/">Clinical studies</a> indicate that side effects
            associated with 5-alpha reductase inhibitors are generally uncommon and may decrease with continued use.
            Sexual side effects, if they occur, are more likely during the initial phase of treatment and often lessen
            over time, becoming comparable to those experienced by individuals taking a placebo by the second year.
            Using dutasteride topically may also reduce the likelihood of systemic side effects compared to oral forms
            of either dutasteride or finasteride.
          </P>
        </div>

        <div className="mb12">
          <P className="bold">What to Do if You Experience Side Effects</P>
          <P>
            If you notice any side effects, regardless of their severity, inform your healthcare provider. They can
            guide you on managing symptoms or adjust your treatment plan if necessary. If you experience suicidal
            thoughts, behaviors, a medical emergency, or an allergic reaction, seek immediate help by contacting
            emergency services (911) or visiting the nearest emergency room.
          </P>
        </div>

        <div className="mb12">
          <P className="bold">References and Further Reading</P>
          <P>
            You can refer to the FDA prescribing information and the Prescriber's Digital Reference (PDR) for
            comprehensive overviews of dutasteride. For more details on studies related to dutasteride use, please
            visit:
          </P>
          <ol className="list-style-type">
            <li>
              <a href="https://pubmed.ncbi.nlm.nih.gov/27672412/">
                Adverse effects and safety of 5-alpha reductase inhibitors finasteride and dutasteride: A systematic
                review
              </a>
            </li>
            <li>
              <a href="https://academic.oup.com/jcem/article-abstract/92/5/1659/2598215">
                Effect of 5α-Reductase Inhibition with Dutasteride and Finasteride on Semen Parameters and Serum
                Hormones in Healthy Men
              </a>
            </li>
          </ol>
        </div>
      </div>

      {renderFooter()}
    </div>
  );
};

export default WarriorSafetyAcknowledgment;
