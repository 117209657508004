export enum AvailableProducts {
  Warrior = 'warrior',
  Lover = 'lover',
  King = 'king',
  KingLab = 'king_lab',
  Magician = 'magician',
  MagicianLab = 'magician_lab',
}

export enum KingV2SubProducts {
  Enclo = 'enclomiphene',
  EncloPregnolone = 'enclomiphene_pregnolone',
  OralTrt = 'oral_trt',
  EncloPregnoloneTrt = 'enclomiphene_pregnolone_oral_trt',
  EncloTrt = 'enclomiphene_oral_trt',
  Topical = 'topical_trt',
  EncloTopical = 'enclomiphene_topical_trt',
  Injectable = 'injectable_trt',
  EncloInjectable = 'enclomiphene_injectable_trt',
  InjectableHCG = 'injectable_trt_hcg',
}

export enum MagicianSubProducts {
  Semaglutide = 'semaglutide',
  Tirzepatide = 'tirzepatide',
}

export enum LoverSubProducts {
  '2_5_mg' = '2_5_mg',
  '5_mg' = '5_mg',
  '10_mg' = '10_mg',
}

export enum WarriorSubProducts {
  OralMinoxidil = 'oral_minoxidil',
  OralDutasteride = 'oral_dutasteride',
  OralFinasteride = 'oral_finasteride',
  TopicalMinoxidil = 'topical_minoxidil',
  TopicalDutasteride = 'topical_dutasteride',
  OralDutasterideOralMinoxidil = 'oral_dutasteride_oral_minoxidil',
  OralMinoxidilTopicalDutasteride = 'oral_minoxidil_topical_dutasteride',
  OralDutasterideTopicalMinoxidil = 'oral_dutasteride_topical_minoxidil',
  TopicalFiveInOne = 'topical_five_in_one',
}

export const MultiMonthPlanMapping = {
  1: 'Monthly',
  3: 'Quarterly',
  6: 'Semiannual',
  12: 'Yearly',
};

export const UserSelectedSubProductMapping = {
  [KingV2SubProducts.Enclo]: ['enclo', 'enclo+preg'],
  [KingV2SubProducts.OralTrt]: ['oral_trt'],
  [KingV2SubProducts.EncloTrt]: ['oral_trt+enclo', 'oral_trt+enclo+preg'],
  [KingV2SubProducts.Topical]: ['top_trt'],
  [KingV2SubProducts.EncloTopical]: ['top_trt+enclo'],
  [KingV2SubProducts.Injectable]: ['inj_trt'],
  [KingV2SubProducts.EncloInjectable]: ['inj_trt+enclo'],
  [KingV2SubProducts.InjectableHCG]: ['inj_trt+hcg'],
};

export const loverPriceMapping = {
  '2_5_mg': 99.99,
  '5_mg': 149.99,
  'legacy': 149.99,
  4: 59.99,
  8: 109.99,
  12: 159.99,
};

export enum LabKitTypes {
  AtHomeLabKit = 'at_home_lab_kit',
  WalkIn = 'lab_walk_in',
  UseYourOwnLab = 'use_own_lab',
}
