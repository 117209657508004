import React from 'react';

import { P } from 'app/components/common/Typography';
import { useFormikContext } from 'formik';
import { AvailableProducts } from 'app/constants/Products';
import nextBillingCycle from 'app/helpers/nextBillingCycle';
import WarriorSubProductsList from 'app/components/customer/steps/Payment/warrior/WarriorSubProductsList';
import WarriorSubProductScreen from 'app/components/customer/steps/Payment/warrior/WarriorSubProductScreen';

type Props = {
  firstTimeChoice?: boolean;
  availableSubProducts: any;
  handleSubmit: () => void;
};

const WarriorProductSelectionForm = ({ availableSubProducts, firstTimeChoice, handleSubmit }: Props) => {
  const { values } = useFormikContext<{
    warrior_strength: string;
  }>();
  const nextBillingCycleStr = nextBillingCycle(AvailableProducts.Warrior);

  const renderAlert = () => (
    <div className="product-selection-alert mb32">
      <P className="mb12">
        <b>NOTE:</b> Any changes you make to your product will take effect after your next billing cycle on{' '}
        <b>{nextBillingCycleStr}</b>.
      </P>
      <P>
        If you would like it sooner,{' '}
        <a target="_blank" className="underlined" href="mailto:support@maximustribe.com" rel="noreferrer">
          contact a Maximus concierge team member
        </a>{' '}
        to make a change to your current subscription.
      </P>
    </div>
  );

  const renderBody = () => {
    const selectedProduct = values.warrior_strength;

    if (!selectedProduct) {
      return <WarriorSubProductsList availableSubProducts={availableSubProducts} />;
    }

    return <WarriorSubProductScreen handleSubmit={handleSubmit} />;
  };

  return (
    <>
      {!firstTimeChoice && renderAlert()}
      {renderBody()}
    </>
  );
};

export default WarriorProductSelectionForm;
